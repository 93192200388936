import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab-gibka.module.scss';

function TabGibka({ tab }) {

	const { name } = tab;

	return (
		<section className={styles.wrapper}>
			{name === '1' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>2,5 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>5 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>8 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>10 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10,01 мм</td>
							<td className={styles.table__item}>12 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>12,01 мм</td>
							<td className={styles.table__item}>16 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === '2' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>4 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>5 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>9 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>14 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10,01 мм</td>
							<td className={styles.table__item}>27 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>12,01 мм</td>
							<td className={styles.table__item}>45 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === '3' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>5 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>8 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>14 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>18 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10,01 мм</td>
							<td className={styles.table__item}>45 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>12,01 мм</td>
							<td className={styles.table__item}>90 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === '4' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>6 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>11 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>18 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>27 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10,01 мм</td>
							<td className={styles.table__item}>63 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>12,01 мм</td>
							<td className={styles.table__item}>270 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === '5' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>9 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>14 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>23 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>36 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10,01 мм</td>
							<td className={styles.table__item}>81 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === '6' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>18 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>27 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>36 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>54 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>10,01 мм</td>
							<td className={styles.table__item}>270 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === '7' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>0</td>
							<td className={styles.table__item}>36 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2,51 мм</td>
							<td className={styles.table__item}>72 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5,01 мм</td>
							<td className={styles.table__item}>108 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>8,01 мм</td>
							<td className={styles.table__item}>135 руб.</td>
						</tr>
					</tbody>
				</table>
			}
		</section>
	);
}

TabGibka.propTypes = {
	tab: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
};

export default TabGibka;
