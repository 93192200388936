import React from 'react';
import styles from './main-page.module.scss';
import Price from '../price/price';
import Header from '../header/header';
import Footer from '../footer/footer';
import Uslugi from '../uslugi/uslugi';
import Constructions from '../constructions/constructions';
import Rezka from '../rezka/rezka';
import Offer from '../offer/offer';
import MainTop from '../main-top/main-top';
import Steps from '../steps/steps';
import About from '../about/about';
import Contacts from '../contacts/contacts';
import Gallery from '../gallery/gallery';
import ScrollButton from '../scroll-button/scroll-button';
import { video } from '../../const';

function MainPage() {
  return (
    <div className={styles.wrapper}>
      <Header />
      <main className={styles.main}>
        <MainTop video={video[0]} />
        <Uslugi />
        <Constructions />
        <Rezka />
        <About usluga='all' />
        <Price usluga='all' />
        <Steps />
        <Offer />
        <Gallery usluga='all' />
        <Contacts />
        <ScrollButton />
      </main>
      <Footer />
    </div>
  );
}

export default MainPage;
