import React from 'react';
import styles from './constructions.module.scss';
import sport from '../../images/1sport-sooruzheniya.jpg';
import pavilion from '../../images/2pavilyoni.jpg';
import naves from '../../images/3navezi.jpg';
import angar from '../../images/4angary.jpg';
import besedka from '../../images/5besedki.jpg';
import zabor from '../../images/6ograzhdeniya-zabory.jpg';
import lestnica from '../../images/7lestnici.jpg';
import perila from '../../images/8perila-pandus.jpg';
import reshetka from '../../images/9reshetki.jpg';


function Constructions() {
	return (
		<div className={styles.wrapper} name='constructions'>
			<h2 className={styles.title}>Производство металлоконструкций</h2>
			<div className={styles.list}>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Спортивные сооружения</h3>
					<img className={styles.image} src={sport} width={600} height={400} alt={'Спортивные сооружения'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Павильоны</h3>
					<img className={styles.image} src={pavilion} width={600} height={400} alt={'Павильоны'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Навесы, козырьки и укрытия</h3>
					<img className={styles.image} src={naves} width={600} height={400} alt={'Навесы, козырьки и укрытия'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Ангары, склады, сельскохозяйственные и промышленные сооружения</h3>
					<img className={styles.image} src={angar} width={600} height={400} alt={'Ангары, склады, сельскохозяйственные и промышленные сооружения'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Перила и пандус</h3>
					<img className={styles.image} src={perila} width={600} height={400} alt={'Перила и пандус'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Ограждения, заборы и ворота</h3>
					<img className={styles.image} src={zabor} width={600} height={400} alt={'Ограждения, заборы и ворота'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Лестницы(включая винтовые и спиральные)</h3>
					<img className={styles.image} src={lestnica} width={600} height={600} alt={'Лестницы(включая винтовые и спиральные)'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Беседки</h3>
					<img className={styles.image} src={besedka} width={600} height={600} alt={'Беседки'} />
				</div>
				<div className={styles.item}>
					<h3 className={styles.title_item}>Решетки на окна</h3>
					<img className={styles.image} src={reshetka} width={600} height={600} alt={'Решетки на окна'} />
				</div>
			</div>
			<p className={styles.text}>Металлоконструкции являются важной частью современного строительства и промышленности. Их разнообразие позволяет находить оптимальные решения для различных задач, обеспечивая надежность и долговечность сооружений.</p>
		</div>
	);
}

export default Constructions;

