import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import styles from "./main-top.module.scss";



function MainTop({ video }) {

	const isDesktopOrTablet = useMediaQuery({
		query: '(min-width: 769px)',
	});

	const isMobile = useMediaQuery({
		query: '(max-width: 768px)',
	});


	return (

		<div className={styles.container}>
			<div className={styles.video__wrapper}>
				{isDesktopOrTablet &&
					<video poster={video.poster} className={styles.video} width="750" height="500" autoPlay muted loop playsinline>
						<source src={video.src} type="video/mp4" />
					</video>
				}
				{isMobile &&
					<img className={styles.videoimage} src={video.poster} />
				}
			</div>
			<div className={styles.video__text}>
				<div className={styles.wrapper}>
					<h1 className={styles.title}>{video.title}</h1>
					<p className={styles.text}>Предлагаем услуги по производству металлоконструкций.<br /> Сварка, резка и гибка листового металла.<br /> Делаем детали, заготовки и изделия любой сложности!</p>
				</div>
			</div>
		</div>
	);
}

MainTop.propTypes = {
	video: PropTypes.shape({
		src: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		poster: PropTypes.string.isRequired,
	}),
};

export default MainTop;
