import React from 'react';
import PropTypes from 'prop-types';
import styles from './tab-svarka.module.scss';

function TabSvarka({ tab }) {

	const { name } = tab;

	return (
		<section className={styles.wrapper}>
			{name === 'nerzh' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>1 - 2 мм</td>
							<td className={styles.table__item}>8000 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>2 - 4 мм</td>
							<td className={styles.table__item}>4000 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>4 - 8 мм</td>
							<td className={styles.table__item}>2000 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'aluminii' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>1,5 - 3 мм</td>
							<td className={styles.table__item}>7200 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>3 - 5 мм</td>
							<td className={styles.table__item}>3600 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>5 - 10 мм</td>
							<td className={styles.table__item}>2400 руб.</td>
						</tr>
					</tbody>
				</table>
			}
			{name === 'stal' &&
				<table className={styles.table}>
					<tbody>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>1,5 - 3 мм</td>
							<td className={styles.table__item}>2000 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>3 - 6 мм</td>
							<td className={styles.table__item}>2200 руб.</td>
						</tr>
						<tr className={styles.table__line}>
							<td className={styles.table__item}>6 - 10 мм</td>
							<td className={styles.table__item}>2600 руб.</td>
						</tr>
					</tbody>
				</table>
			}
		</section>
	);
}

TabSvarka.propTypes = {
	tab: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}),
};

export default TabSvarka;
