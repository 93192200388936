import React from 'react';
import styles from './uslugi.module.scss';
import { Link } from 'react-router-dom';
import { AppRoute } from '../../const';

function Uslugi() {
	return (
		<div className={styles.wrapper}>
			<h2 className='visually-hidden'>Услуги</h2>
			<div className={styles.list}>
				<Link to={AppRoute.PLAZMA} className={styles.item}>
					<p className={styles.text_item}>До 60 мм</p>
					<h3 className={styles.title}>Плазменная резка металла</h3>
				</Link>
				<Link to={AppRoute.LAZER} className={styles.item}>
					<p className={styles.text_item}>До 20 мм</p>
					<h3 className={styles.title}>Лазерная резка металла</h3>
				</Link>
				<Link to={AppRoute.GIBKA} className={styles.item}>
					<p className={styles.text_item}>До 8 мм</p>
					<h3 className={styles.title}>Гибка листового металла</h3>
				</Link>
				<Link to={AppRoute.SVARKA} className={styles.item}>
					<p className={styles.text_item}>До 20 мм</p>
					<h3 className={styles.title}>Сварка металла</h3>
				</Link>
			</div>
			<ul className={styles.list_text}>
				<li className={styles.text}>Производим оригинальные и типовые <span className={styles.text_bold}>металлоконструкции любой сложности</span> – от заборов до каркасов зданий.</li>
				<li className={styles.text}>Используем только <span className={styles.text_bold}>качественный металлопрокат</span>, работаем с любым металлом – от алюминия до стали.</li>
				<li className={styles.text}>Осуществляем все виды металлообработки – высокоточную <span className={styles.text_bold}>резку</span>, чистую <span className={styles.text_bold}>сварку</span>, а также <span className={styles.text_bold}>гибку</span> и другие работы.</li>
				<li className={styles.text}>Предоставляем комплекс услуг <span className={styles.text_bold}>под ключ</span>.</li>
			</ul>
		</div>
	);
}

export default Uslugi;
