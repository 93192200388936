import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ScrollToTop from '../scroll-to-top/scroll-to-top';
import MainPage from '../main-page/main-page';
import LazerPage from '../lazer-page/lazer-page';
import PlazmaPage from '../plazma-page/plazma-page';
import GibkaPage from '../gibka-page/gibka-page';
import SvarkaPage from '../svarka-page/svarka-page';
import PricePage from '../price-page/price-page';

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <ScrollToTop />
          <MainPage />
        </Route>
        <Route path="/lazernaya-rezka" exact>
          <ScrollToTop />
          <LazerPage />
        </Route>
        <Route path="/plazmennaya-rezka" exact>
          <ScrollToTop />
          <PlazmaPage />
        </Route>
        <Route path="/gibka-listovogo-metalla" exact>
          <ScrollToTop />
          <GibkaPage />
        </Route>
        <Route path="/svarka-metalla" exact>
          <ScrollToTop />
          <SvarkaPage />
        </Route>
        <Route path="/price" exact>
          <ScrollToTop />
          <PricePage />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;