import React, { useState, useRef } from 'react';
import { IMaskInput } from 'react-imask';
import ReCAPTCHA from 'react-google-recaptcha';
import { sendMessage } from '../../api/telegram';
import styles from './offer.module.scss';

function Offer() {

	const [isOrderComplete, setIsOrderComplete] = useState(false);

	const [capVal, setCapVal] = useState(null);

	const mask = [{ mask: '+ 7 (000) 000-00-00' }];

	const form = useRef();

	const [toSend, setToSend] = useState({
		name: '',
		phone: '',
		message: '',
	});

	const handleSubmit = (e) => {
		e.preventDefault()
		try {
			const message = {
				...toSend,
			};
			sendMessage(message);
			e.target.reset();
			window.grecaptcha.reset();
			setIsOrderComplete(true);
			setTimeout(() => {
				setIsOrderComplete(false);

			}, 2000);
			setCapVal(null);
		} catch (e) {
			console.log(e)
		}
	};

	const handleChange = (e) => {
		setToSend({ ...toSend, [e.target.name]: e.target.value });
	};

	return (
		<div className={styles.wrapper} name='offer'>
			<div className={styles.background}>
				<div className={styles.offer__text}>
					<h2 className={styles.title}>Рассчитаем стоимость<br /> за 30 минут</h2>
					<p className={styles.text}>Оставьте заявку на расчет стоимости металлоконструкции заполнив форму или отправьте ТЗ на <a className={styles.mail} href='mailto:info@metallrezka.ru'>info@metallrezka.ru</a></p>
				</div>
			</div>
			<div className={styles.form__wrapper}>
				{isOrderComplete && (
					<div className={styles.success}>
						<p className={styles.success__text}>Форма успешно отправлена</p>
						<div className={styles.check}></div>
					</div>
				)}
				<form ref={form} onSubmit={handleSubmit} className={styles.form} >
					<div className={styles.labels}>
						<label className={styles.label} htmlFor="name">Имя *</label>
						<input onChange={handleChange} type="text" id="name" name="name" aria-label="Имя" className={styles.input} required></input>
					</div>
					<div className={styles.labels}>
						<label className={styles.label} htmlFor="phone">Телефон *</label>
						<IMaskInput className={styles.input} required onChange={handleChange} id="phone" mask={mask} name="phone" placeholder="+7 (___) ___-__-__" />
					</div>
					<div className={styles.labels}>
						<label className={styles.label} htmlFor="about">Комментарий</label>
						<textarea onChange={handleChange} id="about" className={styles.textarea} name="message" aria-label="Комментарий" rows="5"></textarea>
					</div>
					<div className={styles.captcha}>
						<ReCAPTCHA onChange={(val) => setCapVal(val)} sitekey="6LfIkDEpAAAAAKmXA3L5NxfXmPULMhblcnojLNFb" />
					</div>
					<button disabled={!capVal} className={styles.button_form} type="submit" aria-label="Отправить">Отправить</button>
				</form>
			</div>
		</div >
	);
}

export default Offer;