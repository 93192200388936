import React from 'react';
import styles from './svarka-page.module.scss';
import Price from '../price/price';
import Header from '../header/header';
import Footer from '../footer/footer';
import Rezka from '../rezka/rezka';
import Offer from '../offer/offer';
import MainTop from '../main-top/main-top';
import Steps from '../steps/steps';
import About from '../about/about';
import Contacts from '../contacts/contacts';
import ScrollButton from '../scroll-button/scroll-button';
import { video } from '../../const';
import Constructions from '../constructions/constructions';

function SvarkaPage() {
	return (
		<div className={styles.wrapper}>
			<Header />
			<main className={styles.main}>
				<MainTop video={video[0]} />
				<h1 className='visually-hidden' >Сварка металла</h1>
				<About usluga='svarka' />
				<Price usluga='svarka' />
				<Rezka />
				<Steps />
				<Offer />
				<Constructions />
				<Contacts />
				<ScrollButton />
			</main>
			<Footer />
		</div>
	);
}

export default SvarkaPage;
