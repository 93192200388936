import videoplazma from './images/banner1.mp4';
import videolazer from './images/banner2.mp4';
import posterplazma from './images/posterplazma.jpg';
import posterlazer from './images/posterlazer.jpg';
import videoall from './images/banner-glav-metallrezka.mp4';
import poster from './images/poster.jpg';

const tabsPlazma = [
	{
		id: 0,
		name: 'stal',
		tabName: 'Сталь черная',
	},
	{
		id: 1,
		name: 'nerzh',
		tabName: 'Нержавающая сталь',
	},
	{
		id: 2,
		name: 'aluminii',
		tabName: 'Алюминий',
	},
	{
		id: 3,
		name: 'latun',
		tabName: 'Латунь',
	},
	{
		id: 4,
		name: 'med',
		tabName: 'Медь',
	},
];

const tabsLazer = [
	{
		id: 0,
		name: 'aluminii',
		tabName: 'Алюминий дюраль (АМГ, АМЦ)',
	},
	{
		id: 1,
		name: 'nerzh',
		tabName: 'Нержавающая сталь',
	},
	{
		id: 2,
		name: 'stal',
		tabName: 'Сталь листовая углеродистая (Г/К и Х/К)',
	},
];

const tabsGibka = [
	{
		id: 0,
		name: '1',
		tabName: 'Толщина до, мм',
	},
	{
		id: 1,
		name: '2',
		tabName: 'Длина от 0 мм',
	},
	{
		id: 2,
		name: '3',
		tabName: 'Длина от 501 мм',
	},
	{
		id: 3,
		name: '4',
		tabName: 'Длина от 1001 мм',
	},
	{
		id: 4,
		name: '5',
		tabName: 'Длина от 2001 мм',
	},
	{
		id: 5,
		name: '6',
		tabName: 'Длина от 2701 мм',
	},
	{
		id: 6,
		name: '7',
		tabName: 'Длина >3201 мм',
	},
];

const tabsSvarka = [
	{
		id: 0,
		name: 'nerzh',
		tabName: 'Нержавающая сталь',
	},
	{
		id: 1,
		name: 'aluminii',
		tabName: 'Алюминий',
	},
	{
		id: 2,
		name: 'stal',
		tabName: 'Сталь',
	},
];


const video = [
	{
		id: 0,
		name: 'all',
		title: 'Металлоконструкции по индивидуальным проектам и чертежам',
		src: videoall,
		poster: poster,
	},
	{
		id: 1,
		name: 'plazma',
		title: 'Плазменная резка металла',
		src: videoplazma,
		poster: posterplazma,
	},
	{
		id: 2,
		name: 'lazer',
		title: 'Лазерная резка металла',
		src: videolazer,
		poster: posterlazer,
	},
]

const AppRoute = {
	MAIN: '/',
	LAZER: '/lazernaya-rezka',
	PLAZMA: '/plazmennaya-rezka',
	GIBKA: '/gibka-listovogo-metalla',
	SVARKA: '/svarka-metalla',
	PRICE: '/price',
};

export { tabsPlazma, tabsLazer, tabsGibka, tabsSvarka, video, AppRoute };